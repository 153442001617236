<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="名称" label-width="50px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" />
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<!-- <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button> -->
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="序号" width="80" align="center">
				  <template slot-scope="scope">
					{{ (queryForm.page - 1) * queryForm.limit + scope.$index + 1 }}
				  </template>
				</el-table-column>
				<el-table-column label="用户名称" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.user_name }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="套数" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.nums }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="提交人" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.submit_name }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="审核人" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.audit_name }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="状态" min-width="70" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.status === 1" size="mini" type="warning">待审核</el-tag>
					<el-tag v-if="scope.row.status === 2" size="mini" type="success">已审核</el-tag>
					<el-tag v-if="scope.row.status === 3" size="mini" type="info">驳回</el-tag>
					<el-tag v-if="scope.row.status === 4" size="mini" type="danger">待复核</el-tag>
				  </template>
				</el-table-column>
<!-- 				<el-table-column label="驳回原因" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.memo }}</span>
				  </template>
				</el-table-column> -->
				<el-table-column align="center" prop="utime" label="时间" min-width="170">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.ctime }}</span>
				  </template>
				</el-table-column>
<!-- 				<el-table-column label="操作" min-width="100" align="center" fixed="right">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="复核" placement="top" v-if="scope.row.status == 4">
					  <el-button type="warning" icon="el-icon-edit-outline" size="mini" circle @click="handleAudit(scope.row)"></el-button>		
		            </el-tooltip>
					<el-tooltip class="item" effect="dark" content="删除" placement="top">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column> -->
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" width="600px" :close-on-click-modal="false" append-to-body :title="title">
		  <el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
		   <el-form-item  label="状态">
		      <el-radio-group v-model="form.status">
		      	<el-radio :label="2">通过</el-radio>
		      	<el-radio :label="3">驳回</el-radio>
		      </el-radio-group>
		    </el-form-item>
			<el-form-item label="驳回原因" prop="mobile" v-if="form.status == 3">
			  <el-input v-model="form.memo" placeholder="请输入驳回原因" type="textarea" :rows="3" clearable />
			</el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button size="mini" @click="cancel">取 消</el-button>
		    <el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'audit_list',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				form:{
					id:'',
					memo:'',
					status:2,
				},
				rules:{
					// username: [
					//   { required: true, message: '请输入名称', trigger: 'blur' }
					// ],
					// role_ids: [
					//   { required: true, message: '请选择角色', trigger: ['blur', 'change'] }
					// ],
				},
			}
		},
		mounted() {
			
		},
		methods:{
			reset() {
			  this.form = {
			   id:'',
			   memo:'',
			   status:2,
			  }
			  this.resetForm('form')
			},
			handleAudit(item){
				this.form.id = item.id
				this.title = '复核'
				this.open = true
			},
			handleSubmit(){
				this.$refs.form.validate(valid => {
				   if (valid) {
					    this.axios.post('/manage/audit_list/operate',this.form).then(res=>{
						  if(res.status){
							this.$message.success('复核成功')
							this.open = false
							this.title = ''
							this.getList()
						  }else{
							this.$message.error(res.msg)
						  }
						})
				   }
				})
			}
		}
	}
</script>

<style>
</style>